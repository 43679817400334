export default () =>
  [
    {
      id: 'document',
      tag: 'InputGroup',
      model: 'document',
      rules: ['required', 'cpfCnpj'],
      props: {
        mask: 'cpfCnpj',
        label: 'CPF ou CNPJ',
        type: 'text',
        floatingLabel: true,
        invalidLabel: 'Documento inválido',
        theme: 'light'
      }
    },
    {
      id: 'confirm',
      tag: 'BaseButton',
      props: {
        label: '',
        theme: 'simple',
        color: 'white',
        iconColor: 'white',
        icon: 'chevron-right-alt',
        type: 'submit'
      }
    }
  ]
