<template>
  <div class="Tracking__Modal">
    <Modal v-if="modal" @close="close">
      <template #header>
        <div class="Tracking__Receipt">
          <div class="Tracking__Receipt__Header">
            {{ order.title }} {{ order.id ? `- (${order.id})` : '' }}
          </div>
        </div>
      </template>
      <template #body>
        <div class="Tracking__Receipt__Content">
          <img :src="order[order.image]" :alt="order.title">
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
import { Modal } from '@/components/molecules'
export default {
  name: 'TrackingOrderImage',
  components: {
    Modal,
  },
  data() {
    return {
      order: null,
      modal: false,
    }
  },
  methods: {
    open({title, image, data}) {
      this.order = {
        title,
        image,
        ...data
      }
      this.modal = true
    },
    close() {
      this.order = null
      this.modal = false
    },
  },
}
</script>
<style lang="scss" scoped>
.Tracking {
  &__Modal {
    display: flex;
    height: auto;

    /deep/ .Modal__container {
      height: auto !important;
      width: auto !important;

      .Modal__containerHeader {
        background-color: $color-primary-base;

        .Modal__close {
          &:hover {
            background-color: $color-primary-base;
            color: white;
            img {
              filter: $filter-white;
            }
          }
        }
      }
      .Modal__containerBody {
        padding: 1rem;
      }
    }
  }

  //Receipt Modal
  &__Receipt {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    &__Header {
      font-weight: $font-weight-bold;
      color: white;
    }

    &__Content {
      display: flex;
      justify-content: center;
      align-content: center;

      img {
        max-height: calc(100vh - 100px);

        @media (orientation: portrait) {
          max-height: initial;
          max-width: calc(100vw - 50px);
        }
      }
    }
  }
}
</style>
