<template>
  <main class="Tracking">
    <section class="Tracking__order">
      <div class="Tracking__header">
        <BaseLogo variation="olistPax" class="Tracking__logo" color="white" />
      </div>

      <div class="Tracking__content">
        <div class="Tracking__table">
          <div class="Tracking__tableHeader">
            <BaseButton class="Tracking__iconHeader" icon="chevron-left-alt" filled color="primary-base" text-color="white" @click="backToOrderPage" />
            <span class="Tracking__order-information">PEDIDO - {{ orderNumber }} {{ orderDate }}</span>
            <Icon v-if="hasComments" name="comment" color="black" class="Tracking__commentHeader" @click="handlerAllChatMessenger" />
          </div>

          <div class="Tracking__infos">
            <DataTable
              :header="validTableHeader"
              :table="validTable"
              :max-size="false"
              @trace="showMap"
              @receipt="handleImage('Comprovante de Entrega', 'confirmation_image', $event)"
              @verificationSign="handleImage('Assinatura', 'verification_signature', $event)"
              @comment="handlerChatMessenger"
            />
          </div>
        </div>

        <div v-if="map && mappedTracking.length === 1 && validTable.length > 0" class="Tracking__mapContainer">
          <div class="Tracking__tableHeader">
            <Icon name="map-pin" class="Tracking__iconHeader" />
            <span class="Tracking__order">MAPA</span>
          </div>

          <div class="Tracking__map">
            <BaseMap ref="map" :markers="mappedTracking" hide-details-button :zoom="12" />
          </div>
        </div>
      </div>
    </section>

    <!-- Chat Messenger -->
    <ChatMessenger 
      ref="ChatMessenger" 
      :messages="trackingCommentsWithStatus" 
      :accept-file="true"
      @fetchMessages="fetchMessages"
      @sendMessage="sendMessage"
      @openImage="handleImage"
    />

    <!-- Receipt Modal -->
    <TrackingOrderImage ref="orderImage" />
  </main>
</template>

<script>
import { BaseLogo, BaseButton } from '@/components/atoms'
import { DataTable, BaseMap, ChatMessenger } from '@/components/molecules'
import { mapActions, mapState, mapGetters } from 'vuex'
import blueprint from '@/views/tracking/Order/TrackingOrder.blueprint'
import TrackingOrderImage from './TrackingOrderImage'
import Mask from '@/plugins/mask/Mask.js'
import { tracking } from '@/api'

export default {
  name: 'Tracking',
  components: {
    BaseLogo,
    DataTable,
    BaseMap,
    BaseButton,
    TrackingOrderImage,
    ChatMessenger
  },

  data(){
    return {
      map: false,
      validation: false,
      form: {
        document: ''
      },
      endpointErrors: {},
      timeOutUpdate: 30,
      time: null,

      //Modal
      selectedOrder: null
    }
  },

  computed:{
    ...mapState({
      ordersHistory: state => state.tracking.ordersHistory,
      trackingComments: state => state.tracking.trackingComments
    }),

    ...mapGetters({
      mappedTracking: 'orders/mappedTracking',
      trackingCommentsWithStatus: 'tracking/trackingCommentsWithStatus'
    }),

    documentInQuery() {
      return this.$route.query.document !== undefined && this.$route.query.document !== ''
    },

    trackingCode() {
      return this.$route.params.trackingCode
    },

    orderNumber() {
      return this.ordersHistory.data ? this.ordersHistory.data.append.order_number : ''
    },

    orderDate() {
      return this.ordersHistory.data
        ? (this.ordersHistory.data.append.date_order ? `(${Mask.millisecondsToDate(this.ordersHistory.data.append.date_order)})` : '')
        : ''
    },

    nf(){
      return this.ordersHistory.data ? this.ordersHistory.data.append.invoice_number : ''
    },

    nfSerie(){
      return this.ordersHistory.data
        ? (this.ordersHistory.data.append.serie_invoice_number ? `/ SÉRIE - ${this.ordersHistory.data.append.serie_invoice_number}` : '' )
        : ''
    },

    validTableHeader(){
      let tableHeader = {}
      if (this.ordersHistory.data) {
        tableHeader = this.ordersHistory.data.headers
        if (tableHeader.actions.actions !== undefined && tableHeader.actions.actions === false) {
          tableHeader.actions = {}
        }        
      }
      return tableHeader
    },

    validTable(){
      const data = this.ordersHistory.data ? this.ordersHistory.data.content : []

      return data.map((order, index) => {
        const hasComments = this.ordersHistory[index]?.has_comments

        if(typeof(hasComments) === 'boolean') {
          const commentActionIndex = order.actions.findIndex((action) => action?.action_id === 'comment')
          order.actions.splice(commentActionIndex, 1, { ...order.actions[commentActionIndex], opacity: !hasComments })
        }
        return order
      })
    },

    hasComments() {
      return Object.values(this.ordersHistory).some((order) => 
        order.has_comments
      )
    },

    hasValidation(){
      return this.ordersHistory.authenticationRequired
    },

    showValidation() {
      let showValidation = false
      if (this.hasValidation && this.documentInQuery) {
        showValidation = false
      } else if (this.hasValidation) {
        showValidation = true
      }
      return showValidation
    },

    orderID(){
      return this.ordersHistory.data ? this.ordersHistory.data.content[0].id : ''
    },

    persedTime(){
      return this.timeOutUpdate ? this.timeOutUpdate * 1000 : null
    }
  },

  mounted(){
    const orderNumber = this.$route?.params?.orderNumber
    const storeId = this.$route?.params?.storeId

    if (this.documentInQuery) {
      this.form.document = this.$route.query.document
      this.onSubmit()
    } else if (orderNumber && storeId) this.fetchTrackingWithStoreId({storeId, orderNumber})
    else this.fetchOrderHistory({code: this.trackingCode})
  },

  beforeDestroy(){
    clearTimeout(this.time)
  },

  methods:{
    blueprint,
    ...mapActions({
      fetchOrderHistory: 'tracking/fetchOrderHistory',
      clearChatMessages: 'tracking/clearChatMessages',
      fetchTrackingComments: 'tracking/fetchTrackingComments',
      fetchAllTrackingComments: 'tracking/fetchAllTrackingComments',
      setLoading: 'loading/setLoading',
      fetchTrackingWithStoreId: 'tracking/fetchTrackingWithStoreId'
    }),

    updateMap(){
      clearTimeout(this.time)
      this.time = setTimeout(() => {
        // this.search()
        this.updateMap()
      }, this.persedTime)
    },

    showMap(data){
      const url = `https://www.google.com/maps/search/?api=1&query=${data.lat},${data.lng}`
      window.open(url, '_blank')
    },

    async onSubmit() {
      await this.fetchOrderHistory({code: this.trackingCode, document: this.form.document})
      this.map = true
      if (this.persedTime || this.map) {
        this.search()
        this.updateMap()
      }
    },

    search(){
      // const obj = {
      //   'order_number[]': this.orderNumber,
      // } 
      // if (localStorage.getItem('access_token')) {
      //   this.fetchOrdersTracking(obj)
      // }
    },

    backToOrderPage() {
      const query = this.$route.query.document
      this.$router.push({
        path: '/tracking',
        query: { document: query}
      })
    },

    //Actions
    handleImage(title, image, data) {
      this.$refs.orderImage.open({title, image, data})
    },

    handlerChatMessenger(data) {
      this.clearChatMessages().then(() => this.$refs.ChatMessenger.open(data.id, { type: 'single' }))
    },

    handlerAllChatMessenger() {
      this.clearChatMessages().then(async () => {
        await this.$refs.ChatMessenger.open(this.orderNumber, { type: 'all', readOnly: true })
      })
    },

    async fetchMessages(id, data) {
      if (data?.type === 'single') {
        await this.fetchTrackingComments(id).then(() => {
          this.$refs.ChatMessenger.scrollMessages()
        })
      } else if (data?.type === 'all') {
        await this.fetchAllTrackingComments(this.orderNumber).then(() => {
          this.$refs.ChatMessenger.scrollMessages()
        })
      }
    },

    async sendMessage(id, message, file) {
      this.setLoading(true)

      const body = new FormData()
      body.append('id', id)
      body.append('text', message)

      if (file) {
        body.append('file', file.file)
      }

      await tracking.submitTrackingMessage({body, id}, async () => {
        await this.fetchTrackingComments(id)
        this.$refs.ChatMessenger.resetInput()
        this.$refs.ChatMessenger.resetFile()
      }, (e) => e, () => this.fetchOrderHistory({code: this.trackingCode}))
    }
  }
}
</script>

<style lang="scss">
.Tracking {
	background-image: url('../../../assets/images/tracking/bg_login.png');
	background-position: center;
	width: 100%;
	min-height: calc(100vh - 40px);

	.Tracking__order {
		display: flex;
		width: 100%;
		height: 100%;
		min-height: calc(100vh - 40px);
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 3rem;

		@media screen and (max-width: 768px) {
			flex-direction: column;
			padding: 2rem 0;
		}
  }

  .Tracking__header {
    margin: 0;
  }

  .Tracking__logo {
    width: 100%;
    text-align: center;
  }

  .Tracking__logo img {
    width: 234px;
  }
    
  .Tracking__table{
    grid-area: table;
    width: 100%;
    margin-top: 4rem;
    @media screen and (min-width: 768px) {
      width: 1030px ;
    }
  }

  .Tracking__tableHeader {
    background-color: $color-neutral-softest;
    display: flex;
    align-items: center;
    padding: 30px 25px 10px 25px;
    border-radius: $border-radius-medium $border-radius-medium 0 0;
  }

  .Tracking__iconHeader {
    width: 37px;
    height: 37px;
    margin-right: 15px;
  }

  .Tracking__iconHeader img {
    margin-left: 0;
  }

  .Tracking__commentHeader {
    width: 25px;
    height: 25px;
    margin-left: auto;
    padding-right: 18px;
    cursor: pointer;

    img {
      height: 25px;
    }
  }

  .Tracking__order-information {
    font-weight: $font-weight-bold;
    font-size: $font-size-2xlarge;
    text-transform: lowercase;
    color: $color-neutral-stronger;
    margin-top: 0px;
  }

  .Tracking__infos {
    background-color: $color-neutral-softest;
    padding: 10px 25px 30px;
    border-radius: 0 0 $border-radius-medium $border-radius-medium;
    min-height: 240px;
  }

  .DataTable {
    text-transform: lowercase;
  }

  .Tracking__mapContainer {
    grid-area: map;
  }

  .Tracking__map {
    height: 50vh;
    margin-bottom: 50px;
  }

}
</style>